<template>
  <div
    class="relative rounded-md shadow-lg bg-white overflow-hidden group
      grid grid-rows-[auto_auto_auto_auto_auto_1fr] border border-gray-200"
    :class="{
      'laptop:grid-cols-[25.6em_auto_1fr_auto] laptop:grid-rows-[auto_auto_auto_1fr]': props.landscape
    }"
  >
    <auth-only :roles="[ UserRoleKey.EDITOR, UserRoleKey.MANAGER, UserRoleKey.ADMINISTRATOR ]">
      <NuxtLink
        :to="localePath({
          name: 'news-news-edit',
          params: {
            news: props.news.id!!
          }
        })"
        class="absolute left-auto right-4 top-4 bottom-auto hidden group-hover:block
          bg-primary hover:bg-primary-highlighted active:bg-primary-selected text-white
          text-center rounded-full p-2 font-semibold"
      >
        <font-awesome-icon
          icon="pen"
          class="h-[1.2em] w-[1.2em]"
        />
      </NuxtLink>
    </auth-only>

    <NuxtLink
      :to="localePath({
        name: props.news.published ? 'news-news' : 'news-news-edit',
        params: {
          news: props.news.published ? props.news.slug : props.news.id
        }
      })"
      class="relative aspect-video h-full w-full"
      :class="{
         'laptop:row-span-4': props.landscape
      }"
    >
      <span
        v-show="!props.news.published"
        class="absolute rounded-md overflow-hidden shadow-lg border border-gray-200 bg-white py-1 px-2 text-slate-900 font-light top-2 left-2"
      >
        {{ t('jc.component.newsListItem.notPublished') }}
      </span>
      <NuxtPicture
        :src="image.src"
        :alt="image.alt || ''"
        :title="image.title"
        sizes="100vw mobile:640px"
        loading="lazy"
      />
    </NuxtLink>

    <span
      class="bg-primary h-full w-full
        min-h-[5px] min-w-[5px] laptop:min-h-[8px] laptop:min-w-[8px]"
      :class="{
         'laptop:row-span-4': props.landscape
      }"
    />

    <div
      class="mx-8 mt-6 text-slate-900"
      :class="{
         'laptop:col-span-2': props.landscape
      }"
    >
      <div v-html="title" />
    </div>

    <div
      class="flex flex-row gap-2 mx-8 mt-6"
      :class="{
         'laptop:row-start-4 laptop:col-start-3 laptop:mt-auto laptop:mb-6': props.landscape
      }"
    >
      <span
        class="b2 text-slate-700"
      >
        {{ t(`jc.news.type.${props.news.type}.title`) }}
      </span>
      <span
        v-if="props.news.publishDate"
        class="b2 text-slate-700"
      >
        {{ d(props.news.publishDate, 'short') }}
      </span>
    </div>

    <div
      class="mx-8 mt-2 mb-4 text-slate-900"
      :class="{
         'laptop:col-span-2': props.landscape
      }"
    >
      <div v-html="teaser" />
    </div>

    <NuxtLink
      v-show="props.news.published"
      :to="localePath({
        name: 'news-news',
        params: {
          news: props.news.slug
        }
      })"
      class="bg-primary hover:bg-primary-highlighted active:bg-primary-selected text-white
        text-center rounded py-2 mx-8 mt-auto mb-6 font-semibold"
      :class="{
         'laptop:row-start-4 laptop:col-start-4 laptop:w-[12rem]': props.landscape,
      }"
    >
      {{ t('jc.component.newsListItem.readMoreButton.label') }}
    </NuxtLink>

    <NuxtLink
      v-show="!props.news.published"
      :to="localePath({
        name: 'news-news-edit',
        params: {
          news: props.news.id
        }
      })"
      class="bg-secondary hover:bg-secondary-highlighted active:bg-secondary-selected text-white
        text-center rounded py-2 mx-8 mt-auto mb-6 font-semibold"
      :class="{
         'laptop:row-start-4 laptop:col-start-4 laptop:w-[12rem]': props.landscape,
      }"
    >
      {{ t('jc.component.newsListItem.editButton.label') }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import type {FileReference, News, UserRoleKey} from "~/utils/charon-types";
import { generateTeaser } from "~/utils/news-generate";
import {generateHTML} from "@tiptap/html";
import {generateText} from "@tiptap/core";
import {teaserExtensions, titleExtensions} from "~/tiptap/config";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const { d, t } = useI18n()
const localePath = useLocalePath()
const charon = useCharon()

const props = defineProps<{
  news: News
  // tries to use landscape mode on laptop or higher
  landscape?: boolean
}>()

const { data: fileReference } = useAsyncData(
  `file-reference-${props.news.image}`,
  () => props.news.image ? charon<FileReference>(`/file-references/${props.news.image}`) : Promise.resolve(null)
)

const image = computed(() => ({
  src: props.news.image ? `/charon/${props.news.image}` : "images/component/charonFile/news_placeholder.png",
  alt: fileReference.value?.description,
  title: fileReference.value?.description,
}))

const title = computed(() => {
  const title = props.news.title
  const hasTitle = title.type === 'doc'
  if (!hasTitle) {
    return undefined
  }
  // tries to change the heading level from a h1 to a h3
  if (title.content?.[0]?.attrs?.level) {
    title.content[0].attrs.level = 3
  }
  return generateHTML(title, titleExtensions({ customHeadingLevel: 3 }))
})

const teaser = computed(() => {
  const hasTeaser = props.news.teaser && generateText(props.news.teaser, teaserExtensions()).length > 0
  // note that generate teaser returns only content that can be rendered by the teaser editor
  const content = hasTeaser ? props.news.teaser : generateTeaser(props.news.body)
  if (!content) {
    return undefined
  }
  return generateHTML(content, teaserExtensions())
})
</script>

<style scoped lang="postcss">
:deep(.h1) {
  @apply text-inherit
}

:deep(img) {
  @apply w-full h-full object-cover
}

#title > :deep(*) {
  @apply line-clamp-2
}
#teaser :deep(*) {
  @apply line-clamp-4
}
</style>
